import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import PuntosDV from '../components/PuntosDV'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query sanityPuntosDv {
    sanityPuntosDv(title: {eq: "Puntos de Venta"}) {
      title
      _rawBody
    }
  }
`

const PuntosDVPage = props => {
  const {data, errors} = props
  const {title} = data.sanityPuntosDv
  const post = data && data.sanityPuntosDv
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <Layout>
      <SEO title='info' />
      <Container>
        <h1 className={responsiveTitle1}>{ title }</h1>
        {post && <PuntosDV {...post} />}
      </Container>
    </Layout>
  )
}

export default PuntosDVPage
