import React from 'react'
import PortableText from './portableText'
import styles from './author-list.module.css'

function PuntosDV (props) {
  const {_rawBody} = props
  return (
    <div className={styles.root}>
      {_rawBody && <PortableText blocks={_rawBody} />}
    </div>
  )
}

export default PuntosDV
